.card_body {
    background-color: #D9CCC5;
    border-radius: 10px;
    padding: 15px;
}

.page_title h3 {
    margin: 0;
}

.main_button {
    background-color: transparent;
    border: 1px dashed #403836 !important;
    color: #403836 !important;
    padding: 10px 50px;
    border-radius: 0px;
}

.small_button,
.small_button:hover {
    background-color: #403836 !important;
    border: 1px dashed #403836 !important;
    color: #fff !important;
    padding: 3px 5px;
    border-radius: 0px;
    font-size: 12px;
    border-radius: 5px;
}

.main_button:hover {
    background-color: #403836 !important;
    color: #fff !important;
}

textarea.form_input {
    padding: .375rem .75rem;
    border: none;
}

/*-=-=-=-=-=-=-=-=-add job form-=-=-=-=-=-=-*/

.form_input {
    color: #403836;
    border-radius: 0px !important;
    width: 100%;
    margin-bottom: 20px;
}

.form_input::placeholder {
    color: grey !important;
}
.welcome_admin{
    width: 325px;
    margin-top: 200px;
    animation: welcome 5s infinite;
    animation-direction: alternate;
    position: relative;
  }
  
  @keyframes welcome {
    0%   {color: red; left: 0px; top: 0px;}
    25%  {color: #403836; left: 15%; top: 0px;}
    50%  {color: blue; left: 35%; top: 0px;}
    75%  {color: green; left: 55%; top: 0px;}
    100% {color: red; left: 70%; top: 0px;}
  }