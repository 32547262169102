.card_body {
    background-color: #D9CCC5;
    /* border-radius: 10px; */
    padding: 15px;
}

.page_title h3 {
    margin: 0;
}

.main_button {
    background-color: transparent;
    border: 1px dashed #403836 !important;
    color: #403836 !important;
    padding: 10px 50px;
    border-radius: 0px;
}

.small_button,
.small_button:hover {
    background-color: #403836 !important;
    border: 1px dashed #403836 !important;
    color: #fff !important;
    padding: 3px 5px;
    border-radius: 0px;
    font-size: 12px;
    border-radius: 5px;
}

.main_button:hover {
    background-color: #403836 !important;
    color: #fff !important;
}

textarea.form_input {
    padding: .375rem .75rem;
    border: none;
}

/*-=-=-=-=-=-=-=-=-add job form-=-=-=-=-=-=-*/

.form_input {
    color: #403836;
    border-radius: 0px !important;
    width: 100%;
    margin-bottom: 20px;
    background-color: #fff;
}

.form_input::placeholder {
    color: grey !important;
}

.edit_icon_btn svg {
    color: #000;
    cursor: pointer;
    padding: 5px;
    font-size: 32px;
    margin-left: 5px;
}

.delete_icon_btn svg {
    margin-left: 5px;
    color: red;
    padding: 5px;
    cursor: pointer;
    font-size: 32px;
}

.view_icon_btn svg {
    color: #000;
    padding: 5px;
    cursor: pointer;
    font-size: 32px;
}

.MuiDataGrid-virtualScroller.css-1w5m2wr-MuiDataGrid-virtualScroller::-webkit-scrollbar {
    width: 6px !important;
}

/* Track */
.MuiDataGrid-virtualScroller.css-1w5m2wr-MuiDataGrid-virtualScroller::-webkit-scrollbar-track {
    background: #403836 !important;
    border-radius: 8px;
}

/* Handle */
.MuiDataGrid-virtualScroller.css-1w5m2wr-MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb {
    background: #D9CCC5;
}

/* Handle on hover */
.MuiDataGrid-virtualScroller.css-1w5m2wr-MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover {
    background: #D9CCC5;

}

.delete_icon_btn svg {
    margin-left: 5px;
    color: red;
    padding: 5px;
    cursor: pointer;
    font-size: 32px;
}

.preview_img {
    width: 100%;
    height: 300px;
    background-size: 100% 100%;
}

.table_img {
    margin: 5px;
    border-radius: 5px;
}
.multi_image_preview {
    padding: 0;
    display: flex;
}

.multi_image_preview li {
    display: inline-block;
    list-style: none;
    margin-left: 10px;
    width: 100px;
}

.multi_image_preview li img {
    width: 100px;
    height: 55px;
    margin-right: 14px;
    object-fit: cover;

}

.upload_list_image {
    position: relative;
}

.upload_list_image .action_btn {
    position: absolute;
    top: -14px;
    right: -12px
}

.action_btn .delete_icon_btn svg {
    color: red;
    font-size: 25px;
}
.preview_img {
    width: 100%;
    height: 100%;
}
.gallery_multiple .action_btn{
    position:absolute;
    top: -2px;
    right: 0px;
}
.gallery_multiple .select_gallery{
height: 15px;
width: 15px;
accent-color: #403836;
cursor: pointer;
}