

.login_form {
    width: 450px;
    margin: 5% auto;
    border-radius: 20px;
    background-color: #D9CCC5 !important;
    padding: 25px;
}

.logo_div {
    text-align: center;
}

.login_form .logo_div {
    margin-bottom: 40px;
}

.form_input {
    color: #403836;
    border-radius: 0px !important;
}

.logo_div img {
    height: 55px;
    width: 180px;
}

.form_input::placeholder {
    color: grey !important;
}

.main_button {
    background-color: transparent;
    border: 1px dashed #403836 !important;
    color: #403836 !important;
    padding: 10px 50px;
    border-radius: 0px;
}

.main_button:hover {
    background-color: #403836 !important;
    color: #fff !important;
}