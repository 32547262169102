/*-=-=-=-=-=-=-=-=-=-=-=-=super big screen media queries0-=-=-=-=-=-*/
@media screen and (min-width: 2560px) and (max-width: 3075px) {
    .MuiContainer-root {
        width: 2700px !important;
        max-width: 3000px !important;
    }

    .css-19r6kue-MuiContainer-root {
        width: 2775px !important;
        max-width: 3000px !important;
    }

    .home-page .big-cards-div {
        margin: 0 170px 71px 50px;
    }

    .home-page .card-design-big-one .cards.heading {
        margin-left: 135px;
    }

    .vskl-home .slider-count-text {

        margin-left: 20%;
    }

    .sub-header button {
        font-size: 18px;
    }

    .cards.heading p {
        font-size: 18px;
    }
}

/*-=-=-=-=-=-=-=-=-=-Mobile screen=-=-=-=-=---=-=-=-=*/
@media screen and (min-width: 320px) and (max-width: 600px) {
    .travel-page-home .motor-bike-section{
        background-size: cover;
    }
    .exclusive-newnew span{
        color: #fff!important;
    }
    .room-type-two .card-description h4{
        font-size: 1.722rem;
    }
   .room-type-two .w-75{
width: 100%!important;
    }
.map-section-div .cards.heading {
    text-align: left;
    width: 100%;
}
.content-cardbox .card-description.mt-150{
margin-top: 0px;
margin-bottom: 0px;
}

    .celebrate-singapore-page.event_block_page .event-card {
        padding: 50px 0;
    }
    .celebrate-singapore-home.celebrate-singapore-page .celebration_cards_main .text-description-div{
padding: 50px 0;
    }

    .celebrate-singapore-page .text-description-div p {
        margin-top: 50px;
        margin-bottom: 30px;
        padding-right: 15px;
    }

    .vskl-home .number-slider.vskl-number-slide .btn-slider-div .react-multi-carousel-item.react-multi-carousel-item--active {
        padding: 0 15px;
    }

    .content_tab_getting .MuiBox-root {
        padding: 0;
    }

    .left_tabs_btn button {
        padding: 5px 0;
    }

    .celebrate-singapore-page .celebration_cards_main .normal-card-div:nth-child(2) {
        margin-top: 0;
    }

    .celebrate-singapore-page .pb-200 {
        padding-bottom: 50px;
    }

    .celebrate-singapore-page .pt-200 {
        padding-top: 50px !important;
    }

    .celebrate-singapore-page .big-font {
        font-size: 1.722rem;
    }

    .crossSideMenu {
        font-size: 20px !important
    }

    .footer-prev .h-64 {
        height: 30px;
    }

    .footer-prev input.form-control {
        padding: 8px 20px 5px;
    }


    .center-slider.exprcne.py-100.btn-slider-div {
        padding: 0;
    }

    .main-bg {
        background-color: #403836 !important;
        padding-top: 75px;
    }

    .travel-motorbike-tours p {
        font-size: 1rem;
        color: #d9ccc5;
        line-height: 29px;
    }

    .list-moterbike li {
        font-size: 1rem;
        line-height: 29px;
    }

    .moterbike-title {
        font-size: 1.722rem;
    }

    .stay-page .page-description-div .page-description-data p {
        font-size: 1rem;
    }

    .cruise-home.cruise-page .taste-center-card .normal-card-section .normal-card-section .MuiGrid-root .MuiGrid-root:nth-child(1) {
        margin-top: 0 !important;

    }

    .motor-bike-section .normal-card-section .normal-card-div:nth-child(4) {
        margin-top: 0;
    }

    .motor-bike-section .normal-card-section .normal-card-div:nth-child(5) {
        margin-top: 0;
    }

    .experience-div-section img {
        margin-top: 30px;
    }

    .reserve-head {
        position: relative;
        font-size: 13px !important;
    }

    .card-design img {
        margin: 0px 0 40px;
    }

    .room-type-two .exprcne.ex-homepage.btn-slider-div li {
        padding-bottom: 50px
    }

    .room-features ul li {
        color: #fff;
        width: 100%;
        line-height: 35px;
        font-size: 14px;
    }

    .vskl-home .number-slider .heading {
        width: 100%;
    }

    .banner-div {
        padding: 0 16px 20px;
    }

    .btn-slider-div li {
        margin: 0px 0rem;
        padding: 0;
    }

    .bottom-card-list .card-design {
        margin: 0 0px;
    }

    .back_sign_sm a {
        font-size: 1rem !important;
    }

    ul.side-menu-list li a {
        font-size: 1.3571428571rem;
    }

    .text_sidemenu {
        font-size: 24px;
    }

    .navbar-main .css-r0uu85-MuiButtonBase-root-MuiButton-root {
        font-size: 12px;
        width: 65px;
    }

    .home-tab-section button {
        width: auto;
    }

    .text-video {
        display: none;
        position: absolute;
        width: 100%;
    }

    .css-hyum1k-MuiToolbar-root {
        min-height: 15px !important;
    }

    .center-big-card-list {
        padding-left: 16px;
        padding-right: 16px;
    }

    .big-cards-div {
        margin: 15px 0;
    }

    .card-design-big-two {
        margin: 30px 0 40px;
    }

    .card-design-big-one .cards.heading {
        margin-left: 0;
    }

    .card-design-big-one img {
        height: 100%;
    }

    .social-link-footer ul {
        padding: 0;
    }

    .social-link-footer {
        display: block;
        margin-top: 25px;
        text-align: center;
    }


    .navbar-main p {
        display: block;
    }

    .logo-header {
        width: 110px;
        height: 30px;
    }

    .headerSmall .logo-header {
        width: 110px;
        height: 30px;
    }

    .navbar-main {
        padding: 15px 0 !important;
    }

    .navbar-main .css-130f8nx {
        display: block;
    }

    .social-link-footer p {
        margin: 5px 0;
    }

    .home-page .home-tab-section button {
        color: #D9CCC5;
        font-size: 20px;
    }

    .home-page .page-description-div .page-description-data p {
        font-size: 23px;
        /*        text-align: justify;*/
    }

    .home-page .page-description-div .page-description-data p br {
        display: none;
    }

    .home-page .home-tab-section {
        margin-bottom: 70px;
    }

    .home-page .page-description-div {
        padding-bottom: 35px !important;
    }

    .home-page .center-big-card-list {
        padding: 0 15px 40px;
    }

    .footer-main::before {
        left: 5px;
        content: "";
        height: 2px;
        width: 100%;
    }

    .sub-header h3 {
        color: #fff;
        font-size: 1.722rem;
        margin-bottom: 20px;
        margin-top: 20px;
    }

    .sub-header {
        padding: 20px 0px 20px 15px;
        margin-top: 0;
    }

    .sub-header button {
        font-size: 13px;
    }

    .sub-header-title {
        justify-content: center;
    }

    .experience-div-section .testimonial-type-card {
        padding: 0;
    }

    .home-page .page-description-div {
        padding: 11px 0 20px;
    }

    /*  .vskl-home .react-multiple-carousel__arrow--left {
        bottom: 20px;
        left: 20%;
        background-color: transparent;
    }*/

    /*  .vskl-home .react-multiple-carousel__arrow--right {
        bottom: 15px;
        right: 20%;
        background-color: transparent;
    }*/

    .vskl-home .slider-count-text {
        margin-top: -65px;
        margin-left: 23%;
    }

    .book-offer-div .section-head {
        margin-bottom: 0;
        display: block;
    }

    .experience-div-section {
        padding: 30px 16px 50px 16px !important;
    }

    .sub-header-title {
        justify-content: center !important;
    }

    .gallery-div-masonary .gallery-card img {
        width: 100%;
        height: 100%;
        object-fit: unset;
    }

    .gallery-div-masonary .gallery-card {
        margin-bottom: 20px;
    }

    .vskl-gallery-modal.gallery-one .gallery-div-massonary-min .react-multiple-carousel__arrow--right {
        bottom: 42px;
        right: 20%;
    }

    .vskl-gallery-modal.gallery-one .gallery-div-massonary-min .react-multiple-carousel__arrow--left {
        bottom: 50px;
        left: 20%;
    }

    .vskl-gallery-modal.gallery-one .react-multi-carousel-item.react-multi-carousel-item--active img {
        height: auto;
    }

    .sub-header .MuiSelect-select {
        font-size: 25px;
    }

    .stay-home.stay-page .page-description-div {
        padding-bottom: 40px !important;
        margin-top: 12px;
    }

    .stay-title {
        font-size: 44px;
    }

    .stay-home.stay-page .motor-bike-section .normal-card-section .normal-card-div:nth-child(2) {
        margin-top: 0px;
    }

    .stay-home.stay-page .motor-bike-section .normal-card-section::before {
        left: 0;
        width: 100%;
    }

    .stay-home .day-trips-section {
        padding: 30px 0 30px;
    }

    .stay-home .day-trips-section .btn-slider-div {
        margin-top: 20px;
    }

    .stay-home .experience-div-section {
        padding: 30px 15px 60px 15px;
    }

    .stay-page .slick-dots {
        position: absolute;
        bottom: 10px;
        left: 0px;
    }

    .sub-header ul {
        padding: 0px 15px !important;
        text-align: center;
    }

    .vskl-home .page-description-div {
        padding: 25px 0 35px !important;
    }

    .vskl-home .page-description-div h3 {
        font-size: 1.722rem;
        margin-bottom: 40px;
    }

    .vskl-home .number-slider {
        padding: 0px 0 0px 0px;
        position: relative;
    }

    .cruise-home .room-type-page .banner-div {
        padding: 3px 16px 7px;
    }

    .card-design-big-one {
        margin-top: 0px;
    }

    .section-head {
        color: #d9ccc5;
        margin: 20px 0;
    }

    .digital-gift-card .exclusive-newnew {
        padding-bottom: 120px;
        padding-top: 45px;
    }

    .exclusive-offer-page .stay-section {
        padding: 25px 0 0;
    }

    .btn-slider-div .react-multiple-carousel__arrow--left {
        top: 35%;
    }

    .btn-slider-div .react-multiple-carousel__arrow--right {
        top: 35%;
    }

    .vskl-ex-offer .exclusive-newnew::before {
        left: 0;
        width: 100%;
    }

    span.lazy-load-image-background.blur.lazy-load-image-loaded {
        height: 230px !important;
    }

    .navbar-main .menu-btn-div {
        width: 65px;
        text-align: left;
        margin: 0;
        display: flex;
        justify-content: flex-start;
        padding-left: 0;
    }


    .vskl-ex-offer.vskl-taste .other-div {
        padding-top: 0px;
        padding-bottom: 50px;
    }

    .vskl-ex-offer .other-div .mt-100 {
        margin-top: 20px;
    }

    .banner-slider-card img {
        height: 230px;
    }

    .banner-div img {
        height: 230px !important;
    }

    .exclusive-offer-page .stay-section .normal-card-section::before {
        left: 0;
        width: 100%;
    }

    .celebration-and-event-page .celebration_cards_main .normal-card-section {
        padding-top: 55px;
        padding-bottom: 100px;
    }

    .celebration-and-event-page .normal-card-div {
        margin-bottom: 0px;
    }

    .celebration-and-event-page .experience-div-section {
        padding: 25px 15px 50px 15px;
    }

    .travel-category.vskl-home .slickimg .btn-slider-div img {
        height: auto !important;
    }

    .mobile_footer {
        padding: 0 0px !important;
    }

    .footer-main .MuiGrid-item {
        padding: 0 0 20px 0 !important;
    }

    .vskl-home .day-trips-section {
        padding: 0px 0 35px;
    }

    .map-border-div {
        padding: 50px 0 10px;
    }

    .main-map-div {
        margin-bottom: 50px;
        margin-top: 15px;
    }

    .offer_sm_none {
        display: none;
    }

    .book-offer-div {
        padding: 20px 0 50px;
    }

    .copyright_div {
        text-align: center !important;
        margin-top: 5px;
    }

    .taste-home.taste-page .page-description-div {
        padding: 25px 0 30px !important;
    }

    .taste-center-card .normal-card-section .normal-card-div:nth-child(1) {
        margin-top: 0px;
        margin-bottom: 106px;
    }

    .taste-center-card .normal-card-section .normal-card-div:nth-child(2) {
        margin-top: 50px;
    }

    .taste-home .taste-center-card .normal-card-section .normal-card-div:nth-child(5) {
        margin-top: 0px;
    }

    .taste-home .taste-center-card::before {
        left: 0;
        width: 100%;
    }

    .taste-home .day-trips-section {
        padding: 25px 0 30px;
    }

    .tamarind-spring .page-description-div {
        padding: 50px 0 0 0 !important;
    }

    .tamarind-spring .normal-card-section {
        padding: 0px 0 50px;
    }

    .tamarind-spring .slider-text-data {
        padding: 0 15px;
        margin-top: 60px;
        text-align: center;
    }

    .state_menu {
        margin-top: 30px !important;
    }

    .side-menus-div .small-title.mt-5 {
        margin-top: 20px !important;
    }

    .side-menus-div label {
        color: #d9ccc5;
        margin-bottom: 0;
    }

    .side-menus-div .stay-list span {
        position: relative;
        line-height: 26px;
    }

    .side-menus-div ul {
        padding: 0;
        margin: 0;
    }

    .taste-home .experience-div-section {
        padding: 22px 15px 50px 15px !important;
    }

    .experience-div-section .testimonial-type-card {
        padding: 20px 0 0 0
    }

    .travel-page-home .experience-div-section {
        padding: 25px 15px 52px 15px;
    }

    .travel-page-home .day-trips-section {
        padding: 25px 0 22px;
    }

    .travel-page-home .page-description-div {
        padding: 28px 0 28px !important;
    }

    .footer-main::after {
        left: 10px;
        width: 100%
    }

    .travel-page-home .banner-div {
        margin-top: 0;
    }

    .travel_map img {
        height: auto;
        width: auto;
        margin: 0 auto;
    }

    .map-size {
        padding: 100px 0px 100px 0px;
    }

    .content-moterbike {
        padding: 60px 0px 80px 0px;
    }

    .price-content-box {
        padding: 90px 0px 30px 0px;
    }

    .room-type-page .day-trips-section {
        padding: 35px 0 15px 0;
    }

    .enquiry-form h3 {
        padding-bottom: 50px;
    }

    .enquirt-section {
        padding: 0px 0px 50px 0px;
    }

    .experience-section-page.experience-page .page-description {
        padding: 40px 0 30px;
        position: relative;
    }

    .experience-page #samadhian h3 {
        padding: 35px 0 35px;
    }

    .experience-page #celebrations h3 {
        padding: 35px 0 35px;
    }

    .experience-page .exprcne.btn-slider-div {
        padding-bottom: 40px;
    }

    .experience-page #senses h3 {
        padding: 20px 0 40px;
    }

    .pb-200#celebrations {
        padding-bottom: 40px;
    }

    .experience-page #culture h3 {
        padding: 10px 0 40px;
    }

    #celebrations .pb-200 {
        padding: 0;
    }

    .experience-section-page .experience-div-section {
        padding: 30px 15px 70px 15px;
    }

    .footer-sub .copyright_div {
        text-align: center !important;
    }

    /* .btn-slider-div .react-multi-carousel-item:nth-child(even) {
        transform: translateY(0px);
    }*/

    .btn-slider-div .react-multi-carousel-item.react-multi-carousel-item--active:nth-child(even) {
        padding-bottom: 45px;
        /*            transform: translateY(25px);*/

    }

    /*   .btn-slider-div .react-multi-carousel-item:nth-child(odd) {
            transform: translateY(25px);
        margin-top: 55px;
    }*/

    /*  .btn-slider-div .react-multi-carousel-item.react-multi-carousel-item--active:nth-child(odd) {
            transform: translateY(0px);
        margin-top: 0;
    }*/
    .btn-slider-div .react-multi-carousel-item.react-multi-carousel-item--active {
        transform: translateY(40px) !important;
        padding-bottom: 40px;
    }

    .btn-slider-div .react-multi-carousel-item {
        margin-top: 0 !important;
    }

    .vskl-home .other-sect .react-multi-carousel-item:nth-child(4) {
        margin-top: 0 !important;
    }

    .vskl-experience h3 {
        font-size: 1.722rem;

        padding: 40px 0px 25px 0px;
    }

    .celebrate-singapore-home.celebrate-singapore-page .text-description-div {
        padding: 70px 0 70px;
    }

    .tamarind-spring .tabs-section ul {
        padding: 0;
        display: block;
        text-align: center;
    }

    .tamarind-spring .tabs-section li {
        padding-right: 0;
        margin-bottom: 15px;
    }

    .tamarind-spring .tabs-section {
        padding: 60px 0;
    }

    .tamarind-spring .celebration-internal-div {
        padding: 40px 0 30px;
    }

    .tamarind-spring .day-trips-section {
        padding: 35px 0 40px;
        background-color: #403836;
    }

    .tamarind-spring .experience-div-section {
        padding: 30px 16px 55px 16px;
    }

    .contact-us-page .address-list.tamarind-resort {
        margin-top: 0px;
    }

    .contact-us-page .MuiGrid-item {
        padding-top: 0 !important;
    }

    .contact-us-page .banner-heading {
        padding: 30px 0 30px;
    }

    .contact-us-page .address-list {
        margin-top: 50px;
    }

    .contact-us-page .address-div-right {
        margin-top: 15px;
    }

    .contact-us-page .address-div-pic:nth-child(2) {
        padding-bottom: 25px;
        margin-top: 45px;
    }

    .footer-prev {
        padding: 46px 0 40px;
    }

    .cruise-page .taste-center-card {
        background-image: url(../images/fish-bg.png);
        background-size: cover;
        padding-bottom: 30px !important;
    }

    .cruise-home.cruise-page .taste-center-card .normal-card-section .normal-card-div {
        margin-top: 0 !important;
    }

    .cruise-page .taste-center-card::before {
        left: 15px;
        width: 93%;
    }

    .cruise-page .day-trips-section {
        padding: 30px 0 22px;
    }

    .cruise-page .experience-div-section {
        padding: 26px 15px 50px;
    }

    .cruise-page .page-description-div {
        padding: 0px 0;
        padding-bottom: 45px !important;
    }

    .cruise-page.cruise-home .deck-plan-slider {
        margin-top: 0;
    }

    .cruise-page .section-head {
        color: #050505;
        margin-bottom: 20px;
    }

    .cruise-home .day-trips-section .mt-100 {
        margin-top: 0px;
    }

    .experience-section-page .section.exp-bg {
        background: url(http://localhost:3000/static/media/white-exp.d3f177a….png) no-repeat 0 100px;
        background-size: 100% 100%;
    }

    .experience-thaipusam-page.experience-section-page .experience-content-text {
        padding: 35px 0 0px;
        position: relative;
    }

    .experience-section-page .vsklexprice-banner {
        padding: 40px 0 20px;
    }

    .exclusive-offer-page .stay-section .normal-card-section {
        position: relative;
        padding: 5px 0 40px;
    }

    .exclusive-offer-page .stay-section .mt-100 {
        margin-top: 10px;
    }

    .exclusive-newnew .bottom-cards-div {
        margin-top: 0px;
    }

    .vskl-home .vskl-number-slide .react-multi-carousel-list {
        padding-bottom: 50px;
    }

    .about-us .banner-div+.page-description-div {
        padding: 40px 0 70px !important;
    }

    .about-us .cards-big .cards.heading {
        width: 100%;
        padding-left: 0;
        margin-top: 20px;
    }

    .about-us .page-description-div+.cards-big .normal-card-section {
        /* border-bottom: 1px dashed #D9CCC5; */
        padding: 50px 0 40px;
    }

    .about-us .small-div-card .img-section {
        padding: 0px 0px 0px 0px;
    }

    .about-us .big-div-card .detail-section {
        padding-left: 0px;
        width: 100%;
        margin-top: 25px;
    }

    .about-us .detail-section {
        margin-top: 60px;
        width: 100%;
        margin-bottom: 40px !important;
    }

    .about-us .event-card-small {
        margin-bottom: 40px !important;

    }

    .about-us .page-description-div+.cards-big+.page-description-div+.big-and-small-div+.cards-big+.page-description-div {
        padding: 0px 0 50px !important;
    }

    .about-us .cards-big.last-new {
        position: relative;
        padding: 40px 0 40px;
    }

    .about-us .day-trips-section {
        padding: 50px 0 100px;
    }

    .about-us .react-multi-carousel-list {

        padding-bottom: 50px;
    }

    .crms-heading {
        padding: 20px 0 0px 0;
    }

    .job-apply .banner-div h2 {
        padding: 50px 0;
        font-size: 50px;
    }

    .job-apply .page-description {
        padding: 55px 0 0;
    }

    .job-apply .page-description h3 {
        margin-bottom: 50px;
    }

    .slider-count-text {
        margin-top: 40px;
    }

    .job-card-flex {
        display: block;
        width: 100%;
        margin: 5px 0 15px;
        padding: 25px 15px;
        height: auto !important;
    }

    .property-manager-div {
        width: auto;
    }

    .job-apply .job-apply-card {
        margin-top: 60px;
        padding-bottom: 40px;
    }

    .property-manager-div h4 {
        font-size: 24px;
        margin-bottom: 0px;
    }

    .job-apply button.read-more-btn {
        padding: 0;
        margin-bottom: 12px;
    }

    .job-apply .job-card-flex .small-title {
        margin-bottom: 0;
    }

    .job-apply .job-card-flex .apply-btn-div {
        text-align: center;
        margin-top: 20px;
    }

    .job-detail-page {
        margin-top: 0px;
        height: 832px;
        overflow-y: auto;
        min-width: 360px;
        padding: 20px 0px;
        max-width: 375px;
    }

    .close-job-detail {
        background-color: #fff;
        padding-left: 5px;
        top: 40px;
    }

    .room-type-all .day-trips-section {
        position: relative;
        padding-top: 10px;
    }

    .vskl-taste .banner-div h2 {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .vskl-taste .day-trip-section-bordered {
        padding: 18px 0 30px 0;
    }

    .top_loader_div {
        position: fixed;
        top: 0px;
    }

    .top_loader_div .progress {
        height: 3px;
    }

    img.footer-logo-check {
        text-align: center;
        width: 160px;
        margin: 0 auto;
        display: block;
    }

    .gallery-div-masonary {
        position: relative;
        padding: 50px 0 40px 0;
    }
.vskl-gallery .book-offer-div {
    margin-top: 25px;
    padding: 60px 0 50px;
}
.tamarind-spring .scroll-slider-div.content-none.btn-slider-div img {
    max-height: 350px;
}
    .vskl-gallery .day-trips-section {
        padding: 40px 0 0px;
    }

    .vskl-gallery .react-multi-carousel-track {
        transform: none;
    }

    .react-multi-carousel-item.react-multi-carousel-item--active {
        opacity: 1;
        display: block;
        /*    width: 100%!important;*/
    }

    .book-offer-div h3 {
        font-size: 1.722rem;
        margin-bottom: 20px;
    }

    .cruise-charter .experience-div-section {
        padding: 30px 15px 45px 15px;
    }

    .job-form-page .form-card-div {
        padding: 30px;
    }

    .job-form-page .form-section {
        padding: 0 0 120px;
    }

    .job-form-page .form-card-div:nth-child(3) {
        padding-bottom: 40px;
    }

    .travel-page-home .day-trips-section img {
        height: 500px;
    }

    .home-page .page-description-div .heading-div {
        margin-top: 0px;
    }

    #keyvisual {
        position: relative;
        width: 100%;
        height: 250px;
        background-attachment: inherit;
    }

    .exlusive_enur_btn {
        text-align: center;
    }

    .tamarind-celebration.celebrate-singapore-page .banner-div h2 {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .stay-home.stay-page .motor-bike-section .normal-card-section .normal-card-div:nth-child(1) {
        margin-top: -130px;
    }

    .experience-div-section .section-head h3 {
        margin-bottom: 20px !important;
    }

    .taste-center-card .normal-card-section .normal-card-div:nth-child(4) {
        margin-top: 12px;
    }

    .experience-div-section .slick-slide {
        margin-top: 0px !important;
    }


    .footer-main {
        padding: 10px 15px 0;
    }

    .motor-bike-section .normal-card-section .normal-card-div:nth-child(1) {
        margin-top: 0;
    }

    .travel-page-home .motor-bike-section .normal-card-section {

        padding: 0 0 25px;
    }

    .travel-motorbike h3 {

        padding: 50px 0px 0px;
    }

    .travel-motorbike .banner-div {
        padding: 40px 22px 45px;
    }

    .travel-motorbike .vsklexprice-banner {
        margin-top: 30px;
        padding-bottom: 50px;
    }

    .cruise-page .page-description-data button {
        margin-top: 25px !important;
    }

    .cruise-home.cruise-page .taste-center-card .normal-card-section {
        padding: 0px 0 0;
    }

    .cruise-home .normal-card-section .normal-card-div:nth-child(1) {
        margin-bottom: 15px;
    }

    .cruise-yatch .banner-div {
        margin: 50px 0 0;
    }

    .cruise-yatch p.large-text {

        margin-bottom: 40px;
    }

    .cruise-yatch .banner-div h2 {
        margin-bottom: 45px;
    }

    .cruise-yatch .text-description-div {
        padding: 30px 15px 70px;
    }

    .cruise-yatch .exclusive-card {
        padding-top: 30px;
    }

    .cruise-yatch .experience-div-section {
        padding: 40px 15px 50px 15px;
    }

    .cruise-charter .map-size-circle {
        padding: 70px 0 0px 0;
        margin-top: 25px;
    }

    .cruise-charter .content-moterbike {
        padding: 50px 0px 20px 0px;
    }

    .room-type-page .banner-div {
        padding: 10px 22px 20px;
    }

    /*.btn-slider-div .react-multi-carousel-track{
  width: 100%!important;
}*/
    /*.btn-slider-div .react-multi-carousel-track li {
        height: 230px;
    }*/

    .content-text-style .pb-109 {
        padding-bottom: 40px;
    }

    .content-text-style .pb-109 p {
        padding: 0 15px;
    }

    .room-type-page .content-text-style {
        padding: 39px 0 40px;
    }

    .room-type-page .card-description {
        margin-bottom: 40px;
    }

    .room-type-page .normal-card-section {
        padding: 45px 0 35px;
    }

    .room-type-page .content-cardbox {
        padding: 0px 0 30px 0;
    }

    .banner-slider-card.btn-slider-div .react-multiple-carousel__arrow--left {
        top: 35%;
    }

    .banner-slider-card.btn-slider-div .react-multiple-carousel__arrow--right {
        top: 35%;
    }

    .vskl-taste .banner-div .react-multi-carousel-item:nth-child(7),
    .vskl-taste .banner-div .react-multi-carousel-item:nth-child(4),
    .vskl-taste .banner-div .react-multi-carousel-item:nth-child(11) {
        margin: 0;
    }

    .vskl-taste .banner-div {
        padding: 0 22px 40px !important;
    }

    .wellness-vskl .banner-div h2 {
        padding: 40px 0;
    }

    .wellness-vskl .banner-div {
        padding: 0 22px 15px;
    }

    .wellness-vskl.celebration-home .celebration_cards_main .normal-card-section {
        padding-top: 70px;
        padding-bottom: 12px;
    }

    .vskl-ex-offer .banner-div {
        padding: 0 22px 42px;
    }

    .vskl-ex-offer .celebration_cards_main .normal-card-section {
        padding-top: 70px;
    }

    .vskl-ex-offer .celebration_cards_main .normal-card-div:nth-child(2) {
        margin-top: 0px;
    }

    .vskl-ex-offer .celebration_cards_main .normal-card-div:nth-child(3) {
        margin-top: 0px;
    }

    .vskl-ex-offer.celebration-home .celebration_cards_main .normal-card-div:nth-child(6) {
        margin-top: 0px;
        margin-bottom: 0;
    }

    .vskl-ex-offer .celebration_cards_main .normal-card-section {
        padding-bottom: 50px !important;
    }

    .tab_retreat_detail_page {
        color: #D9CCC5;
        padding-top: 20px;
        padding-bottom: 40px;
    }

    .home-page .bottom-card-list {
        padding: 40px 0 50px !important;
    }

    .home-page .ex-title.pb-120 {
        padding-bottom: 40px !important;
    }

    .content-substainability {
        margin-top: 40px;
    }

    .content-text-style {
        padding: 0 20px;
    }

    .home-tab-section button {
        width: auto;
    }

    .cards.heading h4 {
        font-size: 1.4rem;
    }

    .cards.heading h6 {
        font-size: .722rem
    }

    .cards.heading p {
        font-size: 1rem;
    }

    .cards.heading a {
        font-size: 13px;
    }

    .page-description-data h3 {
        color: #d9ccc5;
        font-size: 1.722rem;
    }

    .geting-text {
        font-size: 1.722rem;
        color: #d9ccc5;
    }

    .experience-div-section .section-head h3 {
        margin-bottom: 70px;
        font-size: 1.722rem;
    }

    .font-45 {
        font-size: 1.722rem;
    }

    .footer-main .MuiTypography-h6 {
        font-size: 1.722rem;
    }

    .taste-page .page-description-div .page-description-data p {
        font-size: 1.722rem;
    }

    .footer-main a,
    .footer-main a:hover {
        color: #050505;
        text-decoration: none;
        font-size: .9285714286rem;
    }

    .cruise-page .room-type-page .page-description-data p {
        font-size: 1rem;
    }

    .dashed-btn {
        font-size: 1rem !important;
    }

    .font-62 {
        font-size: 1.722rem;
    }

    .experience-section-page .page-description p {
        font-size: 1rem;
        line-height: 23px;
    }

    .celebrate-customer-div h3 {
        font-size: 20px;
    }

    .page-description-div .page-description-data p {
        font-size: 1rem !important;
    }

    .room-type-all .card-description h4 {
        font-size: 1.4rem;
        margin-bottom: 8px;
    }

    .card-description p {
        font-size: 1rem;
    }

    .card-description a {
        color: #fff;
        font-size: 13px;
    }

    .exclusiveboox h3 {
        margin: 0px;
        font-size: 1.722rem;
    }

    .banner-div h2 {
        color: #d9ccc5;
        font-size: 1.722rem;
    }

    .vskl-taste .banner-div p {
        font-size: 1rem;
    }

    .ex-title {
        margin: 0px;
        font-size: 1.722rem;
    }

    .page-description-div .heading-div h3 {
        margin: 38px 0 55px;
        font-size: 1.722rem;
    }

    .tamarind-spring .tabs-section li {
        list-style: none;
        font-size: 18px;
    }

    .tamarind-spring .page-description-data h3 {
        color: #d9ccc5;
        font-size: 1.722rem;
    }

    .slider-count-text p {
        margin-top: 39px;
        color: #d9ccc5;
        font-size: 1rem;
    }

    .travel-motorbike h3 {
        text-align: center;
        color: #d9ccc5;
        font-size: 1.722rem;
    }

    .enquiry-form h3 {
        color: #d9ccc5;
        font-size: 1.722rem;
    }

    .btn-send,
    .btn-send:hover {
        font-size: 1rem;
    }

    .question-answer-div-main h3 {
        font-size: 1rem;
    }

    .stay-home .page-description-div .page-description-data p {
        margin-top: 25px;
    }

    .taste-home .taste-center-card .normal-card-section {
        padding: 50px 0 30px;
    }

    .travel-page-home .day-trips-section .mt-100 {
        margin-top: 0px;
    }

    .experience-bg {
        padding-top: 75px;
    }

    .experience-section-page .samadhian-div {
        padding-bottom: 0;
    }

    .tab-button .slider-count-text {
        margin-top: 20px;
    }

    .celebration_cards_main .pb-5 {
        padding-bottom: 15px !important;
    }

    .celebration_cards_main .normal-card-div:nth-child(2) {
        margin-top: 0;
    }


    .celebration_cards_main .normal-card-div:nth-child(3) {
        margin-top: 0;
    }

    .celebration-home.celebration-and-event-page .celebration_cards_main .normal-card-section {
        padding-bottom: 24px;
    }

    .stay-home .btn-slider-div .mt-100 {
        margin-top: 0;
    }

    .vskl-home .number-slider .border-btm {
        margin-top: 29px;
    }

    .map-section-div .border-btm.mt-5 {
        margin-top: 28px !important;
    }

    .day-trips-section.other-sect {
        padding-top: 25px;
        padding-bottom: 28px !important;
    }

    .footer-main br {
        display: block;
    }

    .footer-main .second_section_link .mb-2 {
        margin-top: 0;
    }

    .footer-main .third_section_link .first_head_foot {
        margin-top: 13px;
    }

    .footer-main .third_section_link .second_head_foot {
        margin-top: 20px;
    }

    .footer-main .four_section_link .mb-2 {
        margin-top: 0;
    }

    .footer-sub {
        padding: 32px 0px 50px;
        align-items: center;
    }

    .card-description img {
        margin-bottom: 50px;
    }

    .crib-roomtype .card-design h4 {
        font-size: 1.722rem;
        margin-bottom: 8px;
    }

    .room-type-two .exprcne.ex-homepage .mt-100 {
        margin-top: 0;
    }

    .room-type-two .ex-homepage {
        padding: 10px 0 60px 0;
    }

    .exprice-slider h3 {
        font-size: 1.4rem;
    }

    .vskl-taste .book-offer-div h3 {
        margin-top: 50px;
    }

    .wellness-vskl .book-offer-div h3 {
        margin-top: 50px;
    }

    .wellness-vskl .day-trips-section {
        padding: 0px 0 0px;
    }

    .wellness-vskl .day-trips-section .day-trip-section-bordered {
        position: relative;
        padding: 25px 0 30px 0;
    }

    .vskl-ex-offer .exclusive-newnew {
        padding-top: 30px;
        padding-bottom: 0 !important;
    }

    .celebrate-singapore-page .tab-button {
        position: relative;
        padding: 30px 0 36px;
    }

    .carnival_ticket .celebration-enquiry .enquiry-form {
        padding: 50px 0 0 0;
    }

    .carnival_ticket .celebration-enquiry .enquirt-section {
        padding: 50px 0px 50px 0px;
    }

    .carnival_ticket .enquiry-form h3 {
        padding-bottom: 37px;
    }

    .carnival_ticket .ticket_left_img {
        width: 20%;
    }

    .carnival_ticket .ticket_left_img img {
        width: 100%;
    }

    .carnival_ticket .ticket_right_content {
        width: 80%;
    }

    .pb-213 {
        padding-bottom: 100px;
    }

    .pt-115 {
        padding-top: 50px;
    }

    .mt-150 {
        margin-top: 70px;
    }

    .py-100 {
        padding: 50px 0px;
    }

    .py-200 {
        padding: 45px 0px;
    }

    .pb-200 {
        padding-bottom: 100px;
    }

    .pb-100 {
        padding-bottom: 50px !important;
    }

    .py-170 {
        padding: 75px 0px !important;
    }

    .pt-200 {
        padding-top: 100px !important;
    }

    .pb-120 {
        padding-bottom: 60px !important;
    }

    .pb-15 {
        padding-bottom: 8px !important;
    }

    .pt-20 {
        padding-top: 10px !important;
    }

    .mt-100 {
        margin-top: 50px;
    }

}

/*=-=-=-=-=-=-=-=-=-=-=-=Mac Book Screen css=-=-=--=-=-=-=*/
@media screen and (min-width: 1398px) and (max-width: 1449px) {
    .banner-slider-card img {
        height: 835px;
    }

    .card-design-big-one img {
        height: 500px;
        /* object-fit: contain; */
        width: 100%;
        margin-left: 0;
    }

    .gallery-slider-height-div {
        padding: 5% 0;
    }

    .page-description-div .heading-div h3 {
        margin: 38px 0 55px;
        font-size: 33px;
    }

    .btn-slider-div .react-multi-carousel-item.react-multi-carousel-item--active:nth-child(even) {
        /*        transform: translateY(0px);*/
        position: absolute;
    }

    .vskl-home .number-slider .heading {
        width: 100%;
    }

    .font-62 {
        font-size: 50px;
    }

    .about-us .page-description-div+.cards-big+.page-description-div {
        padding: 70px 0 50px !important;
    }

    .about-us .page-description-div+.cards-big+.page-description-div+.big-and-small-div {
        padding-bottom: 87px;
    }

    .about-us .page-description-div.ab-privacy p {
        line-height: 38px;
        margin-bottom: 0;
    }


}


@media screen and (min-width: 1300px) and (max-width: 1430px) {
    .MuiContainer-root {
        max-width: 1290px !important;
    }

    .gallery-div-masonary::before {
        width: 99.5%;
    }
}


@media screen and (min-width: 1430px) and (max-width: 1620px) {
    .MuiContainer-root {
        max-width: 1440px !important;
    }
}

@media screen and (min-width: 1700px) and (max-width: 1800px) {

    .MuiContainer-root {
        max-width: 1580px !important;
    }

    .experience-div-section .testimonial-type-card {
        padding-left: 70px;
    }

    .big-cards-div {
        margin: 0 80px 70px 50px;
    }

    .card-design-big-one .cards.heading {
        margin-left: 80px;
    }

    .home-page .center-big-card-list:after {
        left: 4.5%;
        height: 2px;
        width: 90%;
    }

    .footer-main::before {
        left: 2.2%;
        content: "";
        height: 2px;
        width: 99%;
    }

    .footer-main::after {
        left: 2.2%;
        content: "";
        height: 2px;
        width: 99%;
    }
}

/*@media screen and (min-width: 1820px) and (max-width: 2050px){
     .MuiContainer-root {
    max-width: 1820px!important;
}
.navbar-main {
    padding-left: 25px !important;
    padding-right: 20px !important;
}
.home-page .center-big-card-list:after {
    left: 3%;
    content: "";
    height: 2px;
    width: 95%;
}
.footer-main::before {
    left: 2.2%;
    content: "";
    height: 2px;
    width: 99%;}
    .footer-main::after{
    left: 2.2%;
    content: "";
    height: 2px;
    width: 99%;
    }
}*/



@media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {
    #keyvisual {
        position: relative;
        width: 100%;
        height: 500px;
        background-attachment: inherit;
    }

    .big-cards-div {
        margin: 0 25px 70px 50px;
    }

    .card-design-big-one .cards.heading {
        margin-left: 25px;
    }

    .footer-main .MuiGrid-item {
        padding: 0 0 30px 0 !important;
    }

    .stay-home.stay-page .motor-bike-section .normal-card-section .normal-card-div:nth-child(2) {
        margin-top: 0;
    }

    .experience-div-section .testimonial-type-card {
        padding-left: 16px;
    }

    .sub-header {
        margin-top: 20px;
        background-color: #403836;
        padding: 20px 16px 65px;
        text-align: center;
    }

    .sub-header .sub-header-title {
        justify-content: center !important
    }

    .home-page .page-description-div {
        padding: 11px 0px 20px;
        background-color: #403836;
    }
}


@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    #keyvisual {
        height: 400px;
        background-position: top;
    }

    .big-cards-div {
        margin: 0 25px 70px 50px;
    }

    .card-design-big-one .cards.heading {
        margin-left: 25px;
    }

    .stay-home.stay-page .motor-bike-section .normal-card-section .normal-card-div:nth-child(2) {
        margin-top: 0;
    }

    .banner-div img {
        width: 100%;
        height: 400px;
    }
}