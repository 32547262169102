  .MuiDataGrid-row {
    max-height: 200px !important;
    height: 110px;
}

/* .MuiDataGrid-virtualScroller {
    min-height: 700px !important;
} */
.MuiDataGrid-cell {
    max-height: 200px !important;
    height: 110px;
}

.celebrationEvent_card_body__Znfne :where(.css-dev-only-do-not-override-diro6f).ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-container, :where(.css-dev-only-do-not-override-diro6f).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-container, :where(.css-dev-only-do-not-override-diro6f).ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item-container, :where(.css-dev-only-do-not-override-diro6f).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item-container {
    display: inline-block!important;
}