.card_body {
    background-color: #D9CCC5;
    /* border-radius: 10px; */
    padding: 15px;
}

.page_title h3 {
    margin: 0;
}

.main_button {
    background-color: transparent;
    border: 1px dashed #403836 !important;
    color: #403836 !important;
    padding: 10px 50px;
    border-radius: 0px;
    text-decoration: none;
}

.small_button,
.small_button:hover {
    background-color: #403836 !important;
    border: 1px dashed #403836 !important;
    color: #fff !important;
    padding: 3px 5px;
    border-radius: 0px;
    font-size: 12px;
    border-radius: 5px;
}

.main_button:hover {
    background-color: #403836 !important;
    color: #fff !important;
}

textarea.form_input {
    padding: .375rem .75rem;
    border: none;
}

/*-=-=-=-=-=-=-=-=-add job form-=-=-=-=-=-=-*/

.form_input {
    color: #403836;
    border-radius: 0px !important;
    width: 100%;
    margin-bottom: 20px;
}

.form_input::placeholder {
    color: grey !important;
}
.edit_icon_btn svg {
    color: #000;
    cursor: pointer;
    padding: 5px;
    font-size: 32px;
    margin-left: 5px;
}

.delete_icon_btn svg {
    margin-left: 5px;
    color: red;
    padding: 5px;
    cursor: pointer;
    font-size: 32px;
}

.view_icon_btn svg {
    color: #403836;
    padding: 5px;
    cursor: pointer;
    font-size: 32px;
}
